:root {
    --midnight-blue: #2C3E50;
    --soft-blue: #3498DB;
    --emerald-green: #2ECC71;
    --soft-yellow: #F1C40F;
    --light-coral: #E74C3C;
    --lavender: #9B59B6;
    --off-white: #ECF0F1;
    --dark-gray: #7F8C8D;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Lato', sans-serif;
    color: var(--midnight-blue);
    background-color: var(--off-white);
}

.home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
}

.home-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px);
    text-align: center;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    /* Ensures it appears above the background */
}

.hero-content {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.hero-text {
    position: relative;
    z-index: 2;
}

.hero-text h1 {
    font-size: 36px;
    color: var(--midnight-blue);
}

.hero-text p {
    font-size: 18px;
    line-height: 1.6;
    color: var(--dark-gray);
}

.hero-image img {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-out;
}

.auth-options {
    margin: 20px 0;
    margin-top: 150px;
}

.forgot-password {
    margin-top: 20px;
    position: relative;
    z-index: 2;
}

.forgot-password a {
    color: var(--soft-blue);
    text-decoration: none;
    transition: color 0.3s;
}

.forgot-password a:hover {
    color: var(--light-coral);
}

.button {
    background: linear-gradient(45deg, #38E7FF, #6B8BFF);
    /* Gradient from the provided image */
    border: none;
    color: var(--off-white);
    padding: 15px 30px;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    position: relative;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.button:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.button:active {
    transform: translate(0, 0);
    box-shadow: 5px 5px 0 var(--dark-gray);
}