/* src/components/PrintPreview.module.css */

.printPreviewContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.previewBox {
    transform-origin: top center;
    margin: 0 auto;
    transition: all 0.3s ease;
}