:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

.profile-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    width: 100%;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    width: 100%;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.profile-content.sidebar-active {
    margin-left: 250px;
}

.title {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-picture {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 12px;
}

.banner-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.business-details {
    text-align: center;
}

.business-details h2 {
    color: var(--midnight-blue);
    margin-bottom: 10px;
}

.business-details p {
    color: var(--dark-gray);
    margin-bottom: 5px;
}

.profile-grid {
    display: grid;
    /* Use auto-fit so empty columns collapse and items expand */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 40px;
    width: 100%;
    /* Remove the space-between property */
    /* Option 1: Stretch items to fill the cell */
    justify-items: stretch;
    /* Option 2 (alternative): Center items in each cell */
    /* justify-items: center; */
}

.profile-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: inherit;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.profile-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.profile-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.profile-card h3 {
    color: var(--midnight-blue);
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.profile-card p {
    color: var(--dark-gray);
    line-height: 1.4;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .profile-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .profile-grid {
        grid-template-columns: 1fr;
    }
}