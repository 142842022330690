/* Global Payment Component Styles */

.payment-section {
  margin-bottom: 2rem;
}

.payment-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.payment-table th,
.payment-table td {
  padding: 0.75rem;
  border-bottom: 1px solid var(--border-color);
}

.payment-table th {
  text-align: left;
  color: var(--text-secondary);
  font-weight: 600;
}

.payment-chip {
  border-radius: 12px;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.payment-chip-pending {
  background-color: #fff8e1;
  color: #f57c00;
}

.payment-chip-completed {
  background-color: #e8f5e9;
  color: #43a047;
}

.payment-chip-failed {
  background-color: #ffebee;
  color: #e53935;
}

.payment-chip-processing {
  background-color: #e3f2fd;
  color: #1976d2;
}

.payment-chip-icon {
  margin-right: 5px;
  font-size: 0.9rem;
}

.payment-form-section {
  background-color: var(--background-light);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.payment-form-title {
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
}

.payment-settings-group {
  margin-bottom: 2rem;
}

.payment-settings-title {
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

.payment-settings-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.payment-settings-label {
  flex: 0 0 30%;
  font-weight: 500;
  color: var(--text-secondary);
}

.payment-settings-value {
  flex: 1;
}

.payment-method-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.payment-method-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-method-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--primary-color);
}

.payment-method-info {
  flex: 1;
}

.payment-method-title {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
}

.payment-method-subtitle {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.payment-method-actions {
  display: flex;
  gap: 0.5rem;
}

.payment-schedule-option {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.payment-schedule-option:hover,
.payment-schedule-option.active {
  border-color: var(--primary-color);
  background-color: rgba(var(--primary-rgb), 0.05);
}

.payment-schedule-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: var(--primary-color);
}

.payment-schedule-info {
  flex: 1;
}

.payment-schedule-title {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
}

.payment-schedule-description {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .payment-settings-row {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .payment-settings-label {
    flex: 0 0 100%;
    margin-bottom: 0.5rem;
  }
  
  .payment-settings-value {
    flex: 0 0 100%;
  }
  
  .payment-method-card {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .payment-method-icon {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
  
  .payment-method-actions {
    margin-top: 1rem;
    width: 100%;
    justify-content: flex-end;
  }
}
