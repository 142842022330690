.employee-dashboard-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.dashboard-main-content {
  display: flex;
  flex: 1;
  position: relative;
}

.dashboard-container {
  flex: 1;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

.dashboard-content {
  padding: 2rem;
  padding-bottom: 6rem;
  transition: margin-left 0.3s ease;
  min-height: calc(100vh - 64px - 50px);
}

.sidebar-active {
  margin-left: 240px;
}

@media (max-width: 900px) {
  .sidebar-active {
    margin-left: 0;
  }
}

.dashboard-overview {
  /* margin-bottom: 2rem; */
}

.dashboard-metrics {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.quick-access-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.action-items-container {
  margin-top: 2rem;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  height: 100%;
}

.metric-card {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s, box-shadow 0.2s;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .dashboard-content {
    padding: 1rem;
  }
}
