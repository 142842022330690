.payments-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.payments-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.payments-content.sidebar-active {
    margin-left: 250px;
}

.title {
    color: var(--midnight-blue);
    margin-bottom: 20px;
}

.subtitle {
    color: var(--midnight-blue);
    margin-bottom: 16px;
}

.payments-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.edit-payments {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.addPaymentMethodButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.addPaymentMethodButton:hover {
    background-color: var(--light-blue);
}

.addPaymentMethodButton svg {
    font-size: 24px;
    margin-right: 8px;
}

.payment-methods-list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.payment-method-item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-method-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardIcon {
    margin-right: 15px;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.error {
    color: var(--light-coral);
    margin-top: 10px;
}

.default-badge {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
    margin-left: 10px;
}

.paymentForm {
    background-color: var(--off-white);
    border-radius: 12px;
    padding: 20px;
    color: var(--midnight-blue);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.cardElementWrapper {
    padding: 10px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    background-color: white;
    margin-bottom: 20px;
}

.paymentMethodOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.paymentMethodOption {
    flex: 1 0 calc(33.333% - 10px);
    padding: 10px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.paymentMethodOption:hover,
.paymentMethodOption.active {
    background-color: var(--light-blue);
    color: #3498db;
}

.payment-method-actions {
    display: flex;
    gap: 10px;
}

.default-badge {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
}

.paymentMethodsList {
    width: 100%;
}

.paymentMethodItem {
    margin-bottom: 16px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cardIcon {
    font-size: 32px;
    margin-right: 16px;
}

.defaultChip {
    margin-left: 16px;
    vertical-align: middle;
    background-color: #4CAF50;
    color: white;
}

.setDefaultButton {
    margin-right: 8px;
}

.paymentMethodActions {
    display: flex;
    align-items: center;
    gap: 16px;
}

.actionButton {
    margin-right: 8px;
}

.actionIcon {
    margin-left: 8px;
}

.actionIcon:hover {
    color: #3498db;
}

.deleteIcon {
    margin-left: 8px;
}

.deleteIcon:hover {
    color: #ff4d4d;
}

@media (max-width: 600px) {
    .paymentMethodActions {
        flex-direction: column;
        align-items: flex-end;
    }

    .actionButton {
        margin-right: 0;
        margin-bottom: 8px;
    }
}
