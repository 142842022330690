.edit-profile-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.edit-profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.edit-profile-content.sidebar-active {
    margin-left: 250px;
}

.title {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.edit-form {
    width: 100%;
    max-width: 600px;
}

.profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-picture img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.profile-picture input {
    display: none;
}

.upload-button {
    cursor: pointer;
    background: linear-gradient(45deg, #38E7FF, #6B8BFF);
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.3s;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.upload-button:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.remove-button {
    margin-top: 10px;
    background-color: var(--light-coral);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.remove-button:disabled {
    cursor: not-allowed;
    background-color: var(--dark-gray);
}

.remove-button:hover:enabled {
    background-color: var(--midnight-blue);
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.submit-button {
    background-color: var(--soft-blue);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--midnight-blue);
}

.submitButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.errorMessage {
    color: var(--light-coral);
    margin-bottom: 20px;
}

.loadingModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .edit-profile-content {
        padding: 10px;
    }
}