:root {
    --light-pastel-blue: #C8E6F9;
    --soft-pink: #F4C7E6;
    --pastel-lavender: #D1B2F0;
    --light-gray: #BDC3C7;
    --dark-muted-blue: #2C3E50;
    --off-white: #ECF0F1;
}

.dashboard-header {
    background-color: var(--light-pastel-blue);
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 60px;
    width: 100%;
    top: 0;
    z-index: 998;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.logo {
    height: 50px;
    transition: transform 0.3s, fill 0.3s;
}

.logo:hover {
    transform: scale(1.1);
    fill: var(--pastel-lavender);
}

.user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
    margin-right: 20px;
}
.user-info span {
    margin-right: 15px;
    color: var(--dark-muted-blue);
}

.profile-link, .logout-button {
    color: var(--dark-muted-blue);
    text-decoration: none;
    margin-left: 15px;
    font-size: 14px;
    transition: color 0.3s;
    margin-right: 15px;
}

.profile-link:hover, .logout-button:hover {
    color: var(--soft-pink);
}

.logout-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}