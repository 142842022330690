:root {
    --light-pastel-blue: #C8E6F9;
    --soft-pink: #F4C7E6;
    --pastel-lavender: #D1B2F0;
    --light-gray: #BDC3C7;
    --dark-muted-blue: #2C3E50;
    --off-white: #ECF0F1;
}

footer {
    background-color: var(--light-pastel-blue);
    padding: 20px;
    text-align: center;
    color: var(--dark-muted-blue);
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

footer p {
    margin: 0;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
}

footer a {
    color: var(--dark-muted-blue);
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
    opacity: 0.7;
}

footer a:hover {
    color: var(--pastel-lavender);
}