.verificationContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.verificationMain {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

/* Global Styles */
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: var(--font-family);
    color: var(--midnight-blue);
    background-color: var(--off-white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--midnight-blue);
}

p {
    color: var(--dark-gray);
    line-height: 1.6;
}

a {
    color: var(--soft-blue);
    text-decoration: none;
    transition: color var(--transition-speed);
}

a:hover {
    color: var(--light-coral);
}

/* Layout */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 1;
    /* Ensure the container is above the background */
}

.mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    /* Ensure the main content is above the background */
}

/* Buttons */
.button {
    background: linear-gradient(45deg, #38e7ff, #6b8bff);
    border: none;
    color: var(--off-white);
    padding: 15px 30px;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all var(--transition-speed);
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 5px 5px 0 var(--dark-gray);
    display: inline-block;
}

.button:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.button:active {
    transform: translate(0, 0);
    box-shadow: 5px 5px 0 var(--dark-gray);
}

/* Forms */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: var(--midnight-blue);
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-gray);
    border-radius: 4px;
    transition: border-color var(--transition-speed);
}

.form-group input:focus {
    border-color: var(--soft-blue);
    outline: none;
}

/* Card */
.card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: center;
}