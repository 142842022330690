:root {
    --midnight-blue: #2C3E50;
    --soft-blue: #3498DB;
    --emerald-green: #2ECC71;
    --soft-yellow: #F1C40F;
    --light-coral: #E74C3C;
    --lavender: #9B59B6;
    --off-white: #ECF0F1;
    --light-gray: #BDC3C7;
    --dark-gray: #7F8C8D;
}

.navigation {
    display: flex;
    justify-content: center;
    /* Center the navigation links */
    align-items: center;
    width: 100%;
    /* Ensure it takes full width */
}

.navigation a {
    color: var(--off-white);
    margin: 0 15px;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s, transform 0.3s;
}

.navigation a:hover {
    color: var(--soft-blue);
    transform: scale(1.05);
}