:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: var(--font-family);
    color: var(--midnight-blue);
    background-color: var(--off-white);
}

/* Login page styles for use inside PublicLayout */
.login-page {
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
}

.form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 30px;
    border-radius: 12px;
    transition: var(--transition-speed);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    height: auto;
    width: 100%;
    /* Let the content determine the height */
    /* Optionally remove or lower this min-height */
    /* min-height: 500px; */
}

.form-container {
    width: 100%;
    /* Remove fixed height to allow it to scale with its content */
    height: auto;
    min-height: 300px;
    /* Only if you want a minimum size */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition-speed);
}

.form-content:hover,
.form-content:focus-within {
    background-color: rgba(255, 255, 255, 0.95);
    transform: scale(1.03);
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}

.tabs button {
    background: none;
    border: none;
    padding: 15px 25px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: var(--midnight-blue);
    transition: color var(--transition-speed), background-color var(--transition-speed);
}

.tabs button.active {
    border-bottom: 2px solid var(--soft-blue);
    color: var(--soft-blue);
    background-color: rgba(52, 152, 219, 0.1);
}

.tabs button:hover {
    color: var(--soft-blue);
    background-color: rgba(52, 152, 219, 0.1);
}

.tab-descriptions {
    margin-bottom: 20px;
    color: var(--dark-gray);
    font-size: 16px;
}

/* .form-container {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition-speed);
} */