.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1e1e2f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.App-link {
  color: #3498db;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-modern {
  background-color: #3498db;
  border: none;
  color: #ffffff;
  padding: 15px 30px;
  margin: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 5px 5px 0 #7f8c8d;
}

.button-modern:hover {
  background-color: #2ecc71;
  transform: translate(-2px, -2px);
  box-shadow: 7px 7px 0 #7f8c8d;
}

.button-modern:active {
  transform: translate(0, 0);
  box-shadow: 5px 5px 0 #7f8c8d;
}

/* App container */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible; /* Allow content to flow and enable scrolling */
  background-color: transparent !important; /* Force transparent background */
  z-index: 0;
}

/* Layout transition effect */
.page-transition-wrapper {
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
