:root {
  /* Primary colors */
  --primary-color: #007bff;
  --primary-dark: #0056b3;
  --primary-light: #cce5ff;
  
  /* Secondary colors */
  --secondary-color: #6c757d;
  --secondary-dark: #5a6268;
  --secondary-light: #e2e3e5;
  
  /* Business dashboard colors */
  --business-primary: #2c3e50;
  --business-secondary: #34495e;
  --business-accent: #3498db;
  
  /* Employee dashboard colors */
  --employee-primary: #3498db;
  --employee-secondary: #2980b9;
  --employee-accent: #1abc9c;
  
  /* Common colors */
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --info-color: #17a2b8;
  
  /* Text colors */
  --text-dark: #343a40;
  --text-light: #f8f9fa;
  --text-muted: #6c757d;
  
  /* Background colors */
  --bg-light: #f8f9fa;
  --bg-white: #ffffff;
  --bg-dark: #343a40;
  
  /* Border colors */
  --border-color: #dee2e6;
  --border-dark: #ced4da;
  
  /* Shadow */
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}