/* Standardized styles for all user pages to ensure consistent design */

/* Common card styles */
.user-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Interactive card styles (for links and clickable elements) */
.user-card.interactive {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  aspect-ratio: 1 / 1; /* Square aspect ratio */
  height: auto;
  min-height: 180px;
  box-sizing: border-box;
}

/* Gradient accent for cards */
.user-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

/* Standard grid layouts */
.user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
  width: 100%;
  margin-top: 30px;
}

/* Form container */
.user-form-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

/* Section titles */
.section-title {
  color: var(--midnight-blue);
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

/* Page headers */
.page-header {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
}

.page-header h1 {
  color: var(--midnight-blue);
  margin-bottom: 10px;
}

.page-header p {
  color: var(--dark-gray);
  max-width: 800px;
  margin: 0 auto;
}

/* Buttons */
.user-button {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.primary-button {
  background-color: var(--soft-blue);
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: var(--midnight-blue);
  transform: translateY(-2px);
}

.secondary-button {
  background-color: transparent;
  color: var(--midnight-blue);
  border: 1px solid var(--midnight-blue);
}

.secondary-button:hover {
  background-color: rgba(44, 62, 80, 0.05);
  transform: translateY(-2px);
}

/* Loading indicator */
.user-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 100%;
}

/* Error messages */
.user-error {
  color: var(--light-coral);
  background-color: rgba(231, 76, 60, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

/* Success messages */
.user-success {
  color: var(--emerald-green);
  background-color: rgba(46, 204, 113, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

/* Empty state */
.empty-state {
  text-align: center;
  padding: 40px;
  width: 100%;
}

.empty-state-icon {
  font-size: 48px;
  color: var(--dark-gray);
  margin-bottom: 15px;
}

.empty-state-text {
  color: var(--dark-gray);
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .user-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .user-grid {
    grid-template-columns: 1fr;
  }
  
  .user-card {
    padding: 15px;
  }
  
  .section-title {
    font-size: 1.3rem;
  }
}
