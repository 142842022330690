:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1001;
}

.popup-content h2 {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    width: 100%;
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: var(--midnight-blue);
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--dark-gray);
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    border-color: var(--soft-blue);
    outline: none;
}

.error-message {
    color: var(--light-coral);
    margin-bottom: 15px;
    text-align: center;
}

/* Styles for the CustomButton component */
.custom-button {
    background: linear-gradient(45deg, #38E7FF, #6B8BFF);
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    width: 100%;
    margin-top: 20px;
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.custom-button:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.custom-button:active {
    transform: translate(0, 0);
    box-shadow: 5px 5px 0 var(--dark-gray);
}