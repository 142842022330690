:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: var(--font-family);
    color: var(--midnight-blue);
    background-color: var(--off-white);
}

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 160px);
    padding-top: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.contact-page .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 30px;
    border-radius: 12px;
    transition: var(--transition-speed);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 500px;
}

.form-content:hover,
.form-content:focus-within {
    background-color: rgba(255, 255, 255, 0.95);
    transform: scale(1.03);
}

.form-content h1 {
    margin-bottom: 20px;
    color: var(--midnight-blue);
}