/* ===============================================================
   MARKETPLACE APP STYLE GUIDE
   This file provides the standardized style rules for the marketplace
   application. Follow these patterns for consistent UI development.
   =============================================================== */

/* ========================
   1. COLOR PALETTE
   ======================== */

:root {
  /* Core Brand Colors */
  --midnight-blue: #2C3E50;  /* Primary dark color for headers and emphasis */
  --soft-blue: #3498DB;      /* Primary accent color for interactive elements */
  --emerald-green: #2ECC71;  /* Success and positive states */
  --soft-yellow: #F1C40F;    /* Warning and attention states */
  --light-coral: #E74C3C;    /* Error and danger states */
  --lavender: #9B59B6;       /* Information and secondary accent */
  --off-white: #ECF0F1;      /* Light background and text on dark surfaces */
  --dark-gray: #7F8C8D;      /* Subtle text and borders */

  /* Extended Light Palette */
  --light-pastel-blue: #C8E6F9;  /* Light variant of soft-blue */
  --soft-pink: #F4C7E6;          /* Alternative accent for hover states */
  --pastel-lavender: #D1B2F0;    /* Light variant of lavender */
  --light-gray: #BDC3C7;         /* Subtle borders and dividers */

  /* Application Contexts */
  --primary-color: var(--soft-blue);
  --primary-dark: #2980B9;
  --primary-light: var(--light-pastel-blue);

  --secondary-color: var(--midnight-blue);
  --secondary-dark: #1A252F;
  --secondary-light: #34495E;

  /* Business Dashboard */
  --business-primary: var(--midnight-blue);
  --business-secondary: #34495E;
  --business-accent: var(--soft-blue);

  /* Employee Dashboard */
  --employee-primary: var(--soft-blue);
  --employee-secondary: #2980B9;
  --employee-accent: var(--emerald-green);

  /* State Colors */
  --success-color: var(--emerald-green);
  --warning-color: var(--soft-yellow);
  --danger-color: var(--light-coral);
  --info-color: var(--lavender);

  /* Background Colors */
  --bg-light: var(--off-white);
  --bg-white: #FFFFFF;
  --bg-dark: var(--midnight-blue);
  
  /* Typography */
  --font-family: 'Roboto', sans-serif;
  
  /* Transition */
  --transition-speed: 0.3s;
  
  /* Shadows */
  --box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  --box-shadow-sm: 0 4px 8px rgba(0, 0, 0, 0.08);
  --box-shadow-lg: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* ========================
   2. TYPOGRAPHY
   ======================== */

body {
  font-family: var(--font-family);
  color: var(--midnight-blue);
  line-height: 1.5;
}

/* Headings */
.user-page-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--midnight-blue);
  text-align: center;
  width: 100%;
}

.user-page-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--midnight-blue);
  text-align: center;
  width: 100%;
}

.section-title {
  color: var(--midnight-blue);
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

.page-title {
  margin-bottom: 24px;
  color: var(--midnight-blue);
  font-weight: 600;
}

/* ========================
   3. LAYOUT CONTAINERS
   ======================== */

/* Main layout container */
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: transparent !important; 
  z-index: 0; 
}

/* Dashboard page */
.dashboard-page {
  background-color: transparent !important;
  position: relative;
  z-index: 0;
}

/* Business page wrapper */
.business-page {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  min-width: 85vw;
  position: relative;
  background-color: transparent;
  justify-content: center;
  width: 100%;
  backdrop-filter: blur(5px);
}

/* Standard wrapper for all user pages */
.user-page-wrapper {
  width: 100%;
  height: auto; /* Allow height to be determined by content */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent !important;
  padding: 20px;
  position: relative;
  z-index: 1; /* Ensure it appears above background but below header */
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
}

/* Public page wrapper */
.public-page {
  position: relative;
  z-index: 1;
}

/* ========================
   4. CONTENT CONTAINERS
   ======================== */

/* Standard content container - use for most content sections */
.user-page-content {
  width: 100%;
  max-width: 1200px;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1; /* Ensure content appears above background */
  box-sizing: border-box;
  backdrop-filter: blur(5px);
}

/* Narrower content container for profile and form pages */
.user-page-content-narrow {
  width: 100%;
  max-width: 800px;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1; /* Ensure content appears above background */
  backdrop-filter: blur(5px);
  box-sizing: border-box;
}

/* Business page content */
.business-page-content {
  flex: 1;
  transition: margin-left 0.3s ease;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  will-change: margin-left;
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Business content section */
.business-content-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 20px;
}

/* For wider content sections (like tables, charts) */
.business-content-section.wide {
  max-width: 100%;
}

/* For narrower content sections (like forms) */
.business-content-section.narrow {
  max-width: 800px;
}

/* Tables should stretch to full width of their container */
.business-content-section table {
  width: 100%;
}

/* Public content container */
.public-content {
  flex: 1;
  padding: 20px;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 64px - 80px); /* Subtract header and footer heights */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Auth content styles within public layout */
.login-page,
.email-verification,
.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

/* Form container */
.form-content {
  width: 100%;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
}

/* Content centering - unified class for proper content alignment */
.content-centered {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  z-index: 1;
  overflow-y: visible;
  overflow-x: hidden;
  background-color: transparent !important;
  box-sizing: border-box;
}

/* Dashboard container styles */
.dashboard-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 100%;
}

/* Main content area styles */
.dashboard-main-content {
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  background-color: transparent;
  height: calc(100vh - 64px); /* Subtract header height */
  overflow: hidden; /* Keep hidden to prevent double scrollbars */
}

/* Dashboard content styles */
.dashboard-content {
  flex: 1;
  padding: 80px 20px 40px; /* Increased bottom padding */
  margin-left: 60px; /* Collapsed sidebar width */
  transition: margin-left 0.3s ease;
  will-change: margin-left;
  transform: translateZ(0); /* Force hardware acceleration */
  backface-visibility: hidden; /* Prevent flicker */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: transparent !important;
  min-height: auto; /* Allow content to determine height */
  z-index: 0; /* Ensure content appears above background */
  position: relative; /* Establish stacking context */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Expanded sidebar content margin */
.dashboard-content.sidebar-active {
  margin-left: 250px; /* Expanded sidebar width */
}

/* Business page header */
.business-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 20px;
  width: 100%; /* Ensure header spans the full width */
}

.business-page-header h4 {
  color: var(--midnight-blue);
  margin-bottom: 20px;
  font-weight: 600;
}

/* ========================
   5. COMPONENTS
   ======================== */

/* Card styles */
.user-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* Adds a color gradient to the top of cards */
.user-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

/* Interactive card styles */
.user-card.interactive {
  cursor: pointer;
}

.user-card.interactive:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Section card */
.section-card {
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Button styles */
.user-button-primary {
  background: linear-gradient(45deg, var(--soft-blue), var(--emerald-green));
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  font-size: 0.9rem;
}

.user-button-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.user-button-secondary {
  background-color: #f8f9fa;
  color: var(--midnight-blue);
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 600;
  border: 1px solid #dee2e6;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.user-button-secondary:hover {
  background-color: #e9ecef;
}

/* Modern button style (alternative) */
.button-modern {
  background-color: var(--soft-blue);
  border: none;
  color: #ffffff;
  padding: 15px 30px;
  margin: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 5px 5px 0 var(--dark-gray);
}

.button-modern:hover {
  background-color: var(--emerald-green);
  transform: translate(-2px, -2px);
  box-shadow: 7px 7px 0 var(--dark-gray);
}

.button-modern:active {
  transform: translate(0, 0);
  box-shadow: 5px 5px 0 var(--dark-gray);
}

/* Button container styles */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.button-container-center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

/* Form styles */
.user-form {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-section {
  margin-bottom: 20px;
  width: 100%;
}

/* Loading and error states */
.layout-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  width: 100%;
}

.layout-error {
  padding: 20px;
  margin: 20px;
  text-align: center;
}

/* User error and success messages */
.user-error {
  color: var(--light-coral);
  background-color: rgba(231, 76, 60, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.user-success {
  color: var(--emerald-green);
  background-color: rgba(46, 204, 113, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

/* ========================
   6. GRID SYSTEMS
   ======================== */

.user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.user-grid-small {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  width: 100%;
  margin-top: 20px;
}

.user-grid-large {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 25px;
  width: 100%;
  margin-top: 20px;
}

/* ========================
   7. ANIMATIONS & TRANSITIONS
   ======================== */

/* Smooth transitions for all elements */
* {
  transition-property: margin, padding, width, opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

/* Page transition animation */
.page-transition-wrapper {
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* React transition group animations */
.page-transition-enter {
  opacity: 0;
  transform: translateY(10px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

/* ========================
   8. BACKGROUND STYLES
   ======================== */

/* Parallax background container */
.parallax {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  perspective: 1px;
  z-index: -1;
  pointer-events: none;
  will-change: transform;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
}

/* Sidebar backdrop for mobile */
.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.sidebar-backdrop.active {
  display: block;
}

/* ========================
   9. UTILITY CLASSES
   ======================== */

/* Margin utilities */
.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }

/* Width utilities */
.w-100 { width: 100%; }
.w-75 { width: 75%; }
.w-50 { width: 50%; }

/* Text alignment */
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

/* ========================
   10. RESPONSIVE DESIGN
   ======================== */

/* Medium screens */
@media (max-width: 992px) {
  .user-page-content, 
  .user-page-content-narrow {
    max-width: 95%;
    padding: 20px;
  }
  
  .user-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  
  .user-grid-large {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

/* Small screens */
@media (max-width: 768px) {
  .user-page-wrapper {
    padding: 15px;
  }
  
  .user-page-title {
    font-size: 1.75rem;
  }
  
  .business-page-content {
    margin-left: 0;
    margin-right: 0;
    padding: 60px 10px 10px;
    width: 100%; /* Use full width on mobile */
  }
  
  .business-page-content.sidebar-active {
    margin-left: 0;
    width: 100%;
  }
  
  .business-page-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }
  
  .business-page-header > div {
    margin-top: 16px;
    width: 100%;
  }
  
  .dashboard-content {
    margin-left: 0;
    padding: 80px 16px 16px;
  }
  
  .dashboard-content.sidebar-active {
    margin-left: 0;
  }
  
  .business-content-section {
    width: 100%;
    padding: 0 10px;
  }
  
  .user-grid, 
  .user-grid-small {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .user-page-wrapper {
    padding: 10px;
  }
  
  .user-page-content, 
  .user-page-content-narrow {
    padding: 15px;
  }
  
  .user-page-title {
    font-size: 1.5rem;
  }
  
  .user-grid, 
  .user-grid-small, 
  .user-grid-large {
    grid-template-columns: 1fr;
  }
  
  .button-container {
    flex-direction: column;
    gap: 10px;
  }
  
  .button-container .user-button-primary,
  .button-container .user-button-secondary {
    width: 100%;
  }
}

/* ===============================================================
   This style guide should be used as a reference for all
   component and page styling in the marketplace application.
   
   Follow these patterns consistently to maintain visual coherence
   and simplify future development.
   =============================================================== */