/* Analytics specific styles that aren't covered by the common business-page styles */
.business-analytics-page {
  /* Specific styling for analytics, if needed */
}

/* Analytics-header styling is now handled by .business-page-header in LayoutStyles.css */

/* H4 styling is now handled by .business-page-header h4 in LayoutStyles.css */

.performance-dashboard, .reports-dashboard {
  margin-top: 16px;
  padding: 0 20px;
}

/* MUI overrides */
.business-analytics-page .MuiCard-root {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.business-analytics-page .MuiCardHeader-root {
  background-color: var(--light-pastel-blue);
  color: var(--dark-muted-blue);
}

.business-analytics-page .MuiTypography-h4 {
  color: var(--midnight-blue);
  margin-bottom: 20px;
}

/* Chart styles */
.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid var(--light-gray) !important;
}

.recharts-tooltip-label {
  color: var(--midnight-blue) !important;
}

/* Responsive design */
@media (max-width: 768px) {
  .analytics-content {
    margin-left: 0;
    padding: 60px 10px 10px;
  }

  .analytics-content.sidebar-active {
    margin-left: 0;
  }

  .analytics-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }

  .analytics-header > div {
    margin-top: 16px;
    width: 100%;
  }

  .performance-dashboard, .reports-dashboard {
    padding: 0 10px;
  }
}
