.insurance-management {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.0);
    border-radius: 12px;
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.insurance-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.insurance-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: center;
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
    aspect-ratio: 1/1;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

.insurance-card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.insurance-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.insurance-card.add-card {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #f0f8ff;
}

.insurance-card.add-card:hover {
    background-color: #e6f3ff;
}

.card-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

.card-content::-webkit-scrollbar {
    width: 8px;
}

.card-content::-webkit-scrollbar-track {
    background: var(--off-white);
}

.card-content::-webkit-scrollbar-thumb {
    background-color: var(--soft-blue);
    border-radius: 4px;
    border: 2px solid var(--off-white);
}

.card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.9);
    border-top: 1px solid #e0e0e0;
}

.icon-button {
    color: var(--midnight-blue);
    transition: color 0.3s ease;
    padding: 5px;
    margin-left: 10px;
}

.icon-button:hover {
    color: var(--soft-blue);
}

.cancel-button:hover {
    color: var(--light-coral);
}

.delete-button:hover {
    color: var(--light-coral);
}

.edit-button:hover {
    color: var(--soft-blue);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

@media (max-width: 768px) {
    .insurance-management {
        padding: 10px;
    }

    .insurance-grid {
        gap: 20px;
    }

    .insurance-card {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .insurance-management {
        padding: 10px;
        box-shadow: none;
    }
}