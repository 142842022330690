.business-services-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.business-services-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.sidebar-active {
    margin-left: 250px;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.shipping-tiers-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
}

.shipping-tier-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: center;
    height: 500px;
    /* Fixed height */
    max-height: 500px;
    /* Ensure height is limited */
 
    overflow-y: auto;
    /* Enable scrolling within the card */
    aspect-ratio: 1/1;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

.card-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

.shipping-tier-card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.shipping-tier-card.service-add-card {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #f0f8ff;
}

.shipping-tier-card.service-add-card:hover {
    background-color: #e6f3ff;
}

.shipping-tier-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.tier-details {
    padding: 16px;
    overflow-y: auto;
    flex-grow: 1;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

/* Styles for the form inside the card */
.shipping-tier-card form {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 16px;
    /* Space for action buttons */
}

.shipping-tier-card .MuiCardContent-root {
    height: 100%;
    padding: 0;
    overflow: hidden;
}

.card-content::-webkit-scrollbar {
    width: 8px;
}

.card-content::-webkit-scrollbar-track {
    background: var(--off-white);
}

.card-content::-webkit-scrollbar-thumb {
    background-color: var(--soft-blue);
    border-radius: 4px;
    border: 2px solid var(--off-white);
}

.shipping-tier-card .card-content {
    max-height: calc(100% - 30px);
    /* Adjust based on padding or other elements */
    overflow-y: auto;
    padding-right: 10px;
    /* Prevent content from overlapping with the scrollbar */
}

.card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.9);
    border-top: 1px solid #e0e0e0;
}

.icon-button {
    color: var(--midnight-blue);
    transition: color 0.3s ease;
    padding: 5px;
    margin-left: 10px;
}

.icon-button:hover {
    color: var(--soft-blue);
}

.cancel-button:hover {
    color: var(--light-coral);
}

.delete-button:hover {
    color: var(--light-coral);
}

.edit-button:hover {
    color: var(--soft-blue);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.submit-button {
    background-color: var(--soft-blue);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--midnight-blue);
}

.errorMessage {
    color: var(--light-coral);
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .business-services-content {
        margin-left: 0;
        padding: 10px;
    }

    .business-services-content.sidebar-active {
        margin-left: 0;
    }

    .shipping-tiers-grid {
        gap: 20px;
    }

    .shipping-tier-card {
        width: 100%;
    }

     /*add scroll bar to address card*/
    .shipping-tier-card {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--soft-blue) var(--off-white);
    }
}

@media (max-width: 600px) {
    .business-services-content {
        padding: 10px;
        box-shadow: none;
    }

    .shipping-tier-card {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--soft-blue) var(--off-white);
    }
}