:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: var(--font-family);
    color: var(--midnight-blue);
    background-color: var(--off-white);
}

.password-verification-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.password-verification-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.password-verification-content h2 {
    margin-bottom: 20px;
    color: var(--midnight-blue);
    font-size: 2em;
    font-weight: bold;
}

.password-verification-content p {
    color: var(--dark-gray);
    margin-bottom: 20px;
    text-align: center;
}

.password-verification-content.sidebar-active {
    margin-left: 250px;
}

.password-form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-gray);
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 1em;
    transition: border-color var(--transition-speed);
}

.password-input:focus {
    border-color: var(--soft-blue);
    outline: none;
}

.submit-button {
    display: flex;
    justify-content: center;
    width: 100%;
}

.error-message {
    color: red;
    background-color: #ffe6e6;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid red;
    margin-top: 20px;
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--midnight-blue);
}

p {
    color: var(--dark-gray);
    line-height: 1.6;
}

a {
    color: var(--soft-blue);
    text-decoration: none;
    transition: color var(--transition-speed);
}

a:hover {
    color: var(--light-coral);
}