.customers-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.customers-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.sidebar-active {
    margin-left: 250px;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.filter-search {
display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    max-width: 1200px;
}

.filter-control,
.items-per-page {
    width: 200px;
    padding: 10px;
}
.search-field {
    width: 100%;
    max-width: 400px;
    padding: 10px;
}

.items-per-page {
    min-width: 100px;
}

.customers-grid {
    display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
}

.customer-card {
    padding: 20px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        transition: transform 0.3s, box-shadow 0.3s;
        cursor: pointer;
}

.customer-card:hover {
    transform: translateY(-5px);
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.card-actions {
    display: flex;
        justify-content: space-between;
        margin-top: 20px;
}

.icon-button {
    color: var(--midnight-blue);
    transition: color 0.3s ease;
    padding: 5px;
    margin-left: 10px;
}

.icon-button:hover {
    color: var(--soft-blue);
}

.cancel-button:hover {
    color: var(--light-coral);
}

.delete-button:hover {
    color: var(--light-coral);
}

.edit-button:hover {
    color: var(--soft-blue);
}

.icon-button.delete-button {
    color: var(--light-coral);
        border-color: var(--light-coral);
}

.icon-button.delete-button:hover {
    color: var(--light-coral);
        border-color: var(--light-coral);
}





.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .customers-content {
        margin-left: 0 !important;
    }

    .filter-search {
        flex-direction: column;
        align-items: stretch;
    }

    .search-field {
        max-width: 100%;
    }

    .customers-grid {
        grid-template-columns: 1fr;
    }
}