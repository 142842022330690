.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
    opacity: 1.0;
}

.MuiTextField-root {
    margin-bottom: 15px;
    width: 100%;
}

.MuiOutlinedInput-root {
    background-color: rgba(255, 255, 255, 0.85);
    transition: var(--transition-speed);
}

.MuiOutlinedInput-root:hover,
.MuiOutlinedInput-root.Mui-focused {
    background-color: rgba(255, 255, 255, 0.95);
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-gray);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--soft-blue);
}

.MuiInputBase-input {
    color: var(--midnight-blue);
}

.MuiInputLabel-root {
    color: var(--dark-gray);
}

.MuiInputLabel-root.Mui-focused {
    color: var(--soft-blue);
}