:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.about-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 20px 50px;
    /* Increased top padding */
    position: relative;
    z-index: 1;
}

.about-title {
    color: var(--midnight-blue);
    font-size: 3rem;
    margin-bottom: 60px;
    /* Increased margin */
    text-align: center;
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    max-width: 1200px;
    width: 100%;
}

.about-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
}

.about-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.about-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.about-card h2 {
    color: var(--midnight-blue);
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.about-card p,
.about-card ul {
    color: var(--dark-gray);
    line-height: 1.6;
}

.about-card ul {
    list-style-type: none;
    padding: 0;
}

.about-card li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

.about-card li::before {
    content: "•";
    color: var(--soft-blue);
    position: absolute;
    left: 0;
}

@media (max-width: 768px) {
    .about-grid {
        grid-template-columns: 1fr;
    }

    .about-content {
        padding-top: 100px;
        /* Slightly less padding on mobile */
    }

    .about-title {
        font-size: 2.5rem;
        /* Smaller font size on mobile */
        margin-bottom: 40px;
    }
}