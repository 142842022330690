.dashboard-footer {
    background-color: var(--light-pastel-blue);
      color: #2C3E50;
      text-align: center;
      padding: 10px 0;
      flex-shrink: 0;
      position: relative;
      z-index: 998;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
}

.footer-section h4 {
  font-size: 16px;
  margin-bottom: 15px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #3a4553;
  text-decoration: none;
  font-size: 14px;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #3a4553;
}

.footer-bottom p {
  font-size: 12px;
  color: #3a4553;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 30px;
  }
}