/* Dashboard specific styles that aren't covered by the common business-page styles */
.business-dashboard-page {
    /* Specific styling for the dashboard, if needed */
    max-width: 100vw; /* Prevent horizontal overflow */
    overflow-x: hidden; /* Hide horizontal overflow */
}

/* Fix for expanding content during loading */
.business-page-content {
    max-width: calc(100vw - 85px) !important; /* Force content to stay within view minus sidebar */
    overflow-x: hidden; /* Hide horizontal overflow */
}

/* Ensure the dashboard overview doesn't expand beyond container */
.dashboard-overview {
    padding: 20px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* Ensure action items don't expand beyond container */
.action-items-section {
    padding: 20px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* MUI overrides */
.MuiCard-root {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.MuiCardHeader-root {
    background-color: var(--light-pastel-blue);
    color: var(--dark-muted-blue);
}

.MuiTypography-h4 {
    color: var(--midnight-blue);
    margin-bottom: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .dashboard-content {
        margin-left: 0;
    }

    .dashboard-content.sidebar-active {
        margin-left: 0;
    }
}

/* Chart styles */
.recharts-default-tooltip {
    background-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid var(--light-gray) !important;
}

.recharts-tooltip-label {
    color: var(--midnight-blue) !important;
}
