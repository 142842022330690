.download-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: transparent !important; 
  z-index: 0;
  padding-bottom: 80px; /* Space for footer */
}

.download-content {
  flex: 1;
  padding: 120px 20px 90px; /* Increased top padding */
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

/* Ensure content is centered and properly spaced */
.download-content .content-centered {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px; /* Additional padding for title spacing */
}

@media (max-width: 768px) {
  .download-content {
    padding: 100px 15px 100px; /* Adjust padding for mobile */
  }
  
  .download-content .content-centered {
    padding-top: 40px;
  }
}