.dashboard-sidebar {
    width: 60px;
    background-color: #f8f9fa;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
    z-index: 1000;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.dashboard-sidebar.active {
    width: 250px;
}

.sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.sidebar-logo {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    margin-right: 10px;
}

.sidebar-logo .logo {
    max-width: 100%;
    height: auto;
    max-height: 40px;
    padding: auto;
    padding-left: 10px;
}

.sidebar-toggle {
    margin-top: 80px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2C3E50;
    padding: 10px;
}

.top-toggle {
    align-self: flex-end;
    margin-right: 10px;
    margin-bottom: 20px;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.dashboard-sidebar.active .top-toggle {
    transform: rotate(180deg);
}

.dashboard-sidebar nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 20px;
}

.dashboard-sidebar a {
    color: #2C3E50;
    text-decoration: none;
    padding: 15px 20px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.dashboard-sidebar a svg {
    min-width: 20px;
    margin-right: 40px;
    font-size: 20px;
}

.dashboard-sidebar a span {
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.dashboard-sidebar.active a span {
    opacity: 1;
}

.dashboard-sidebar a:hover,
.dashboard-sidebar a.active {
    background-color: #C8E6F9;
}

@media (max-width: 768px) {
    .dashboard-sidebar {
        width: 0;
    }

    .dashboard-sidebar.active {
        width: 100%;
    }
}

.sidebar-bottom {
    margin-top: auto;
}

.sidebar-link {
    color: #2C3E50;
    text-decoration: none;
    padding: 15px 20px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.sidebar-link svg {
    min-width: 20px;
    margin-right: 40px;
    font-size: 20px;
}

.sidebar-link span {
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.dashboard-sidebar.active .sidebar-link span {
    opacity: 1;
}

.sidebar-link:hover {
    background-color: #C8E6F9;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.sidebar-overlay.active {
    opacity: 1;
    pointer-events: all;
}
/* Update these styles in your existing CSS file */

.sidebar-submenu {
    padding-left: 0;
    /* Remove left padding */
}

.sidebar-submenu a {
    font-size: 0.9em;
    padding: 10px 20px 10px 40px;
    /* Adjust padding to indent the text, not the whole item */
    width: 100%;
    /* Ensure the link takes up full width */
}

.sidebar-submenu a svg {
    font-size: 16px;
    margin-right: 20px;
    /* Reduce right margin of icon */
    width: 16px;
    /* Set a fixed width for icons to align text */
}

.dashboard-sidebar:not(.active) .sidebar-submenu {
    display: none;
}

.dashboard-sidebar.active .sidebar-submenu {
    display: block;
}

.parent-link {
    margin-bottom: 0;
    /* Remove bottom margin */
}

.dashboard-sidebar:not(.active) .parent-link+.sidebar-submenu {
    display: none;
}

.dashboard-sidebar.active .parent-link.active+.sidebar-submenu {
    display: block;
}

/* Ensure highlighting spans full width */
.dashboard-sidebar a:hover,
.dashboard-sidebar a.active,
.sidebar-submenu a:hover,
.sidebar-submenu a.active {
    background-color: #C8E6F9;
    width: 100%;
}

/* Adjust main menu items to align with submenu items when sidebar is active */
.dashboard-sidebar.active>nav>a,
.dashboard-sidebar.active .sidebar-link {
    padding-left: 20px;
}

/* Ensure consistent left alignment for icons in main menu and submenu */
.dashboard-sidebar a svg,
.sidebar-submenu a svg,
.sidebar-link svg {
    min-width: 20px;
    margin-right: 20px;
    font-size: 20px;
}

/* Adjust submenu icon size */
.sidebar-submenu a svg {
    font-size: 16px;
}