:root {
  --midnight-blue: #2c3e50;
  --soft-blue: #3498db;
  --emerald-green: #2ecc71;
  --soft-yellow: #f1c40f;
  --light-coral: #e74c3c;
  --lavender: #9b59b6;
  --off-white: #ecf0f1;
  --dark-gray: #7f8c8d;
  --font-family: 'Roboto', sans-serif;
  --transition-speed: 0.3s;
}

.services-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.services-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 20px 50px;
  position: relative;
  z-index: 1;
}

.services-title {
  color: var(--midnight-blue);
  font-size: 3rem;
  margin-bottom: 60px;
  text-align: center;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  width: 100%;
}

.services-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

.services-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.services-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.service-icon {
  color: var(--soft-blue);
  margin-bottom: 20px;
  transition: transform var(--transition-speed);
}

.services-card:hover .service-icon {
  transform: scale(1.1);
}

.service-icon svg {
  width: 40px;
  height: 40px;
}

.services-card h2 {
  color: var(--midnight-blue);
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.services-card p {
  color: var(--dark-gray);
  line-height: 1.6;
  margin-bottom: 20px;
}

.services-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.services-card li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  color: var(--dark-gray);
}

.services-card li::before {
  content: "•";
  color: var(--soft-blue);
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }

  .services-content {
    padding-top: 100px;
  }

  .services-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
}

/* Animation for cards */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}