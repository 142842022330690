/* Employee-specific layout customizations */
.employee-layout {
  background-color: transparent !important;
  position: relative;
  z-index: 0;
}

/* Tables should stretch to use full width */
.employee-content-section table {
  width: 100%;
}

/* Table containers should also use full width */
.employee-content-section .MuiTableContainer-root {
  width: 100%;
}

/* Employee content container - consistent with business-content-section */
.employee-content-section {
  width: 100%;
    min-width: 85vw;
      min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(5px);
}

/* For wider content sections (like tables, charts) */
.employee-content-section.wide {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  margin: 0;
}

/* For standard content sections */
.employee-content-section.standard {
  max-width: 1200px;
}

/* For narrower content sections (like forms) */
.employee-content-section.narrow {
  max-width: 800px;
}

/* Employee page header styling */
.employee-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 20px;
  width: 100%;
}

.employee-page-header h4 {
  color: var(--employee-primary, #3498db);
  margin-bottom: 20px;
  font-weight: 600;
}

/* Role indicator banner styling */
.employee-role-banner {
  background-color: var(--employee-primary, #3498db);
  color: white;
  padding: 8px 15px;
  margin: 0 10px 15px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.employee-role-banner .role-icon {
  margin-right: 8px;
  font-size: 1rem;
}

/* Dashboard cards styling */
.employee-dashboard-card {
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background-color: var(--bg-white, #ffffff);
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.employee-dashboard-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

/* Action items styling */
.employee-action-item {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: var(--bg-light, #f8f9fa);
  border-left: 4px solid var(--employee-primary, #3498db);
}

.employee-action-item.urgent {
  border-left-color: var(--danger-color, #dc3545);
}

.employee-action-item.important {
  border-left-color: var(--warning-color, #ffc107);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .employee-page-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }
  
  .employee-role-banner {
    padding: 6px 10px;
    margin: 0 5px 10px 5px;
    font-size: 0.8rem;
  }
  
  .employee-content-section {
    padding: 15px;
  }
}