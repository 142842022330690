/* ShipmentsPage specific styles */
.business-page {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  min-height: 100vh;
  width: 100%;
}

.business-page-content {
  flex: 1;
  padding: 20px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

.tab-content {
  margin-top: 20px;
  width: 100%;
}

/* Fix for ResponsiveContainer in charts */
.dashboard-overview-wrapper {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.dashboard-overview-wrapper .recharts-responsive-container {
  width: 99% !important; /* Prevent overflow */
}

/* MUI overrides */
.MuiCard-root {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.MuiCardHeader-root {
    background-color: var(--light-pastel-blue);
    color: var(--dark-muted-blue);
}

.MuiTypography-h4 {
    color: var(--midnight-blue);
    margin-bottom: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .dashboard-content {
        margin-left: 0;
    }

    .dashboard-content.sidebar-active {
        margin-left: 0;
    }
}

/* Chart styles */
.recharts-default-tooltip {
    background-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid var(--light-gray) !important;
}

.recharts-tooltip-label {
    color: var(--midnight-blue) !important;
}

/* Page transition styles */
.page-enter {
    opacity: 0;
    transform: translateX(100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}