/* Additional shared styles for user pages */

/* Profile section styles */
.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
  border: 3px solid var(--soft-blue);
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-details {
  text-align: center;
}

.profile-details h2 {
  color: var(--midnight-blue);
  margin-bottom: 10px;
}

.profile-details p {
  color: var(--dark-gray);
  margin-bottom: 5px;
}

/* Address form styles */
.address-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.address-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
}

/* Address card styles - unified with other cards */
.address-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.address-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.address-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.add-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.add-card svg {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: var(--soft-blue);
}

/* Business Cards - standardized to match other cards */
.businesses-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  width: 100%;
  margin-top: 20px;
}

.business-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  height: 360px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.business-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.business-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.bannerContainer {
  height: 100px;
  width: 100%;
  overflow: hidden;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileImageContainer {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.businessName {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 70px;
  margin-bottom: 5px;
  text-align: center;
}

.statusChip {
  align-self: center;
  margin-bottom: 10px;
}

/* Orders page styles */
.orders-list {
  width: 100%;
  margin-top: 20px;
}

.order-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
  padding: 16px;
  transition: all 0.3s ease;
}

.order-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.order-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}

.status-pending {
  background-color: #fff8e1;
  color: #ff8f00;
}

.status-shipped {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-delivered {
  background-color: #e3f2fd;
  color: #1565c0;
}

.status-cancelled {
  background-color: #ffebee;
  color: #c62828;
}

.order-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.order-detail-group {
  flex: 1;
  min-width: 200px;
}

.order-detail-label {
  font-size: 12px;
  color: #888;
  margin-bottom: 4px;
}

.order-detail-value {
  font-size: 14px;
  color: #333;
}

/* Payments page styles - improved for better layout */
.payment-methods-list {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-method-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.payment-method-info {
  display: flex;
  align-items: center;
}

.card-icon {
  margin-right: 16px;
  font-size: 24px;
}

.payment-method-details {
  display: flex;
  flex-direction: column;
}

.payment-method-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Button styling - unified */
.user-button, .primary-button, .secondary-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  gap: 8px; /* Space between icon and text */
  box-sizing: border-box;
}

.primary-button {
  background-color: var(--soft-blue);
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: var(--midnight-blue);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.secondary-button {
  background-color: transparent;
  color: var(--midnight-blue);
  border: 1px solid var(--midnight-blue);
}

.secondary-button:hover {
  background-color: rgba(44, 62, 80, 0.05);
  transform: translateY(-2px);
}

/* Standardized Section Card */
.section-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}

.section-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.section-title {
  color: var(--midnight-blue);
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

/* Security page styles */
.security-form {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  overflow: hidden;
}

.security-form h3 {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.form-row {
  margin-bottom: 16px;
  width: 100%;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 12px;
  width: 100%;
}
