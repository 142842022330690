:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
}

.reset-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
    opacity: 1.0;
}