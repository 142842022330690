:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: var(--font-family);
    color: var(--midnight-blue);
    background-color: var(--off-white);
}

.security-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.security-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.security-content.sidebar-active {
    margin-left: 250px;
}

.title {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.display-email,
.display-phone {
    margin-bottom: 20px;
}

.display-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.display-mode p {
    flex-grow: 1;
}

.verification-status {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.verification-failed,
.verification-succeeded,
.verification-pending {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 10px;
    background-color: #f1c40f;
    z-index: 10;
}

.verification-failed {
    background-color: #ffe6e6;
    color: #e74c3c;
    border: 1px solid #e74c3c;
}

.verification-succeeded {
    background-color: #e6ffe6;
    color: #2ecc71;
    border: 1px solid #2ecc71;
}

.verification-pending {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
}

.verification-icon {
    margin-right: 10px;
    font-size: 24px;
}

.verification-text {
    margin: 0;
    font-size: 14px;
}

.verification-pending .verification-text:first-child {
    font-weight: bold;
    margin-bottom: 5px;
}

.edit-button {
    cursor: pointer;
    background: linear-gradient(45deg, #38E7FF, #6B8BFF);
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.3s;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 5px 5px 0 var(--dark-gray);
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-button:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.submit-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}