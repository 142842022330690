/* DownloadPage specific styles, following the marketplace style guide */

/* Header section */
.download-header {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.user-page-title {
  color: var(--midnight-blue) !important;
  font-size: 2.5rem !important;
  margin-bottom: 20px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.user-page-subtitle {
  font-size: 1.2rem !important;
  text-align: center !important;
  color: var(--dark-gray) !important;
  max-width: 800px !important;
  margin: 0 auto 20px !important;
  line-height: 1.6 !important;
}

/* Beta badge */
.beta-badge {
  background-color: var(--soft-blue);
  color: white;
  font-size: 0.5em;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: middle;
  font-weight: bold;
  display: inline-block;
  transform: translateY(-5px);
}

/* QR code section */
.qr-code-image {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.qr-code-image:hover {
  transform: scale(1.05);
}

/* FAQ section */
.faq-container {
  width: 100%;
}

.faq-item {
  border-radius: 8px !important;
  overflow: hidden !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 10px !important;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .user-page-title {
    font-size: 2.2rem !important;
  }
}

@media (max-width: 768px) {
  .user-page-title {
    font-size: 1.8rem !important;
  }
  
  .user-page-subtitle {
    font-size: 1rem !important;
  }
  
  .button-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .user-page-title {
    font-size: 1.5rem !important;
  }
  
  .download-header {
    margin-bottom: 20px;
  }
}