/* src/pages/Business/PrintingSettingsPage.module.css */

.printing-settings-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.printing-settings-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.printing-settings-content.sidebar-active {
    margin-left: 250px;
}

.title {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.section {
    width: 100%;
    margin-bottom: 40px;
}

.downloadButton {
    background: linear-gradient(45deg, #38E7FF, #6B8BFF);
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.3s;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.downloadButton:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.loadingModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .printing-settings-content {
        padding: 10px;
    }
}

@media (max-width: 600px) {
    .printing-settings-content {
        padding: 10px;
        box-shadow: none;
    }
}