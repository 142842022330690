.security-page {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.security-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.security-content.sidebar-active {
    margin-left: 250px;
}

.title {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.edit-form {
    width: 100%;
    max-width: 600px;
}

.form-box {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.form-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.form-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.form-box h3 {
    color: var(--midnight-blue);
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.form-box p {
    color: var(--dark-gray);
    line-height: 1.4;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .security-content {
        padding: 10px;
    }
}