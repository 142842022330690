.title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.filter-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  max-width: 1200px;
}

.filter-control,
.items-per-page {
  width: 200px;
  padding: 10px;
}

.search-field {
  width: 100%;
  max-width: 400px;
  padding: 10px;
}

.add-customer-button {
  margin-bottom: 1.5rem !important;
  min-width: 180px;
}

.action-button-container {
  display: flex;
  gap: 0.5rem;
}

.pagination {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.icon-button {
  color: var(--midnight-blue, #191970);
  transition: color 0.3s ease;
  padding: 5px;
  margin-left: 10px;
}

.icon-button:hover {
  color: var(--soft-blue, #6495ED);
}

.cancel-button:hover {
  color: var(--light-coral, #F08080);
}

.avatar {
  background-color: var(--primary-color);
  margin-right: 0.5rem;
}

.customer-status-chip {
  text-transform: capitalize;
}

/* Responsive styling */
@media (max-width: 768px) {
  .filter-search {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-field {
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .items-per-page {
    width: 100%;
  }
}
