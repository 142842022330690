.pageWrapper {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.custom404Page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    color: var(--midnight-blue);
    text-align: center;
    padding: 20px;
    z-index: 1;
}

.contentContainer {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 40px;
    max-width: 600px;
    width: 100%;
    position: relative;
    z-index: 2;
}

.logoContainer {
    margin-bottom: 30px;
}

.logoContainer img {
    width: 150px;
    height: auto;
}

.errorCode {
    font-size: 6rem !important;
    font-weight: bold !important;
    color: var(--soft-blue) !important;
    margin-bottom: 10px !important;
}

.errorTitle {
    font-size: 2rem !important;
    color: var(--midnight-blue) !important;
    margin-bottom: 20px !important;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.iconContainer svg {
    margin: 0 10px;
    color: var(--emerald-green);
}

.errorMessage {
    font-size: 1.1rem !important;
    color: var(--dark-gray) !important;
    margin-bottom: 30px !important;
    line-height: 1.5 !important;
}

.homeButton {
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    text-decoration: none;
    display: inline-block;
}

.homeButton:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    color: white;
}

@media (max-width: 768px) {
    .contentContainer {
        padding: 30px;
    }

    .errorCode {
        font-size: 4rem !important;
    }

    .errorTitle {
        font-size: 1.5rem !important;
    }

    .errorMessage {
        font-size: 1rem !important;
    }
}

@media (max-width: 480px) {
    .contentContainer {
        padding: 20px;
    }

    .errorCode {
        font-size: 3rem !important;
    }

    .errorTitle {
        font-size: 1.2rem !important;
    }

    .logoContainer img {
        width: 100px;
    }
}