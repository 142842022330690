.registration-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.registration-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.paper {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.paper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.stepper {
    background-color: transparent;
    padding: 24px 0;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button {
    margin-left: 10px;
    background-color: var(--midnight-blue);
    color: var(--off-white);
}

.button:hover {
    background-color: var(--soft-blue);
}

/* Styles for form elements */
.form-control {
    margin-bottom: 20px;
}

.form-control label {
    color: var(--midnight-blue);
    font-weight: 500;
}

.form-control input,
.form-control select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.form-control input:focus,
.form-control select:focus {
    border-color: var(--soft-blue);
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Styles for the review step */
.review-list {
    width: 100%;
}

.review-list-item {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}

.review-list-item:last-child {
    border-bottom: none;
}

.review-list-item-text {
    color: var(--midnight-blue);
}

.review-list-item-text-secondary {
    color: #666;
}

.autocomplete-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    text-align: left;
    padding: 5px 0;
    max-height: 200px;
    overflow-y: auto;
}
.suggestion-item,
.suggestion-item--active {
    padding: 8px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.suggestion-item:hover,
.suggestion-item--active {
    background-color: #f0f0f0;
}

.profile-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 40px;
    width: 100%;
}

.profile-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: inherit;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.profile-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.profile-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.profile-card h3 {
    color: var(--midnight-blue);
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.profile-card p {
    color: var(--dark-gray);
    line-height: 1.4;
    font-size: 0.9rem;
}

.category-icon {
    font-size: 3rem;
    margin-bottom: 10px;
}

.profile-card {
    position: relative;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.selected-card {
    border-color: green;
}

.selected-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.paymentMethodsList {
    width: 100%;
}

.paymentMethodItem {
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

.cardIcon {
    font-size: 24px;
    margin-right: 10px;
}

.formButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .registration-content {
        max-width: 100%;
        padding: 10px;
    }

    .paper {
        padding: 15px;
    }

    .title {
        font-size: 1.5rem;
    }

    .stepper {
        padding: 16px 0;
    }
}