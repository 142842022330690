:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
    --default-gap: 20px;
    --large-gap: 40px;
}

.business-addresses-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    overflow: auto;
}

.hours-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.main-content {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 20px;
}

.business-addresses-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: margin-left var(--transition-speed);
    position: relative;
    z-index: 2;
}

.sidebar-active {
    margin-left: 250px;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.addresses-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.business-address-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: center;
    height: 500px;
    /* Fixed height */
    min-height: 500px;
    max-height: 500px;
    /* Ensure height is limited */
    overflow-y: auto;
    /* Enable scrolling within the card */
    aspect-ratio: 1/1;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

.card-content {
    flex-grow: 1;
    overflow-y: auto;
    /* Ensure scrolling within this content */
    padding: 16px;
    scrollbar-width: thin;
    scrollbar-color: var(--soft-blue) var(--off-white);
}

.card-content::-webkit-scrollbar {
    width: 8px;
}

.card-content::-webkit-scrollbar-track {
    background: var(--off-white);
}

.card-content::-webkit-scrollbar-thumb {
    background-color: var(--soft-blue);
    border-radius: 4px;
    border: 2px solid var(--off-white);
}

.address-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.business-address-card .card-content {
    max-height: calc(100% - 30px);
    /* Adjust based on padding or other elements */
    overflow-y: auto;
    padding-right: 10px;
    /* Prevent content from overlapping with the scrollbar */
}

.business-address-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.business-address-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.business-address-card h3 {
    color: var(--midnight-blue);
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.business-address-card p {
    color: var(--midnight-blue);
    line-height: 1.4;
    font-size: 0.85rem;
    margin-bottom: 3px;
}

.card-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-bottom: 15px;
    box-sizing: border-box;
}

.add-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.service-add-card:hover {
    background-color: #e6f3ff;
        transform: translateY(-5px);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.service-add-card .MuiSvgIcon-root {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.icon-button {
    color: var(--midnight-blue);
    transition: color 0.3s ease;
    padding: 5px;
    margin-right: 10px;
}

.icon-button:hover {
    color: var(--soft-blue);
}

.cancel-button:hover {
    color: var(--light-coral);
}

.delete-button:hover {
    color: var(--light-coral);
}

.edit-button:hover {
    color: var(--soft-blue);
}

.address-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.form-field {
    font-size: 0.8rem;
    height: min-content;
}

.form-field .MuiInputBase-input {
    font-size: 0.8rem;
    padding: 0.5rem;
}

.form-field .MuiInputLabel-root {
    font-size: 0.75rem;
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    width: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    text-align: left;
    padding: 5px 5px;
    max-height: 200px;
    overflow-y: auto;
}

.suggestion-item,
.suggestion-item--active {
    padding: 8px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.suggestion-item:hover,
.suggestion-item--active {
    background-color: #f0f0f0;
}

.autocomplete-wrapper {
    position: relative;
    margin: 0%;
    padding: 0;
}

.address-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    height: 100%;
    width: 100%;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.hours-of-operation {
    margin-top: 10px;
    font-size: 0.85rem;
    justify-content: flex-start;
}

.hours-of-operation h4 {
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: var(--midnight-blue);
}

.hours-of-operation p {
    margin: 2px 0;
}

.hours-of-operation-grid {
    display: flex;
    flex-direction: column;
    /* Ensure items stack vertically */
    align-items: flex-start;
    justify-content: flex-start;
    /* Align items to the top vertically */
}


.hours-row {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.day-column {
    width: 120px;
    /* Adjust this value based on your longest day name */
    flex-shrink: 0;


}


.time-column {
    flex-grow: 1;
}

.day-checkbox {
    margin-right: 20;
    align-items: end;
    padding-right: 50px;
    padding-bottom: 15px;
}

.time-pickers {
    display: flex;
    gap: 10px;
}

.time-pickers .MuiTextField-root {
    width: calc(50% - 5px);
}

.time-picker-error {
    margin-top: 5px;
    color: #f44336;
    font-size: 0.75rem;
    width: 100%;
}

/* Adjusting the size of time picker inputs */
/* .time-pickers .MuiInputBase-root {
    font-size: 0.8rem;
    height: 20px;

}

.time-pickers .MuiInputBase-input {
    padding: 5px 8px;
}

.time-pickers .MuiInputLabel-root {
    font-size: 0.75rem;
    transform: translate(8px, 6px) scale(1);
}

.time-pickers .MuiInputLabel-shrink {
    transform: translate(8px, -6px) scale(0.75);
} */


@media (max-width: 1200px) {
    .addresses-grid {
        gap: 30px;
    }

    .business-address-card {
        height: 320px;
    }
}

@media (max-width: 992px) {
    .addresses-grid {
        gap: 20px;
    }

    .business-address-card {
        height: 300px;
    }
}

@media (max-width: 768px) {
    .addresses-content {
        margin-left: 0;
        padding: 1rem;
    }

    .sidebar-active {
        margin-left: 0;
    }

    .title {
        font-size: 1.5rem;
    }

    .addresses-grid {
        gap: 15px;
    }

    .business-address-card {
        height: 280px;
    }
}

@media (max-width: 480px) {
    .addresses-grid {
        flex-direction: column;
        align-items: center;
    }

    .business-address-card {
        width: 100%;
        max-width: 300px;
        height: auto;
        aspect-ratio: auto;
    }

    /*add scroll bar to address card*/
    .business-address-card {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--soft-blue) var(--off-white);
    }
}