/* Action items specific container */
.business-action-items {

    max-width: 100%;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    overflow: hidden;
    box-sizing: border-box;
}

/* Constrain content width to prevent expanding */
.business-action-items .MuiCardContent-root {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

.action-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.warning-card {
    border-left: 4px solid #f44336 !important;
}

/* Loading skeleton */
.action-items-skeleton {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
