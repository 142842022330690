.employeeBusinessesContainer {
    width: 100%;
    margin-bottom: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
}

.businessesList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;
}

.businessCard {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 350px;
    height: 350px;
    box-sizing: border-box;
    cursor: pointer;
}

.businessCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--soft-blue), var(--emerald-green));
}

.businessCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .employeeBusinessesContainer {
        padding: 1rem;
    }

    .sectionTitle {
        font-size: 1.3rem;
    }

    .businessesList {
        gap: 20px;
    }

    .businessCard {
        width: 100%;
        max-width: 250px;
    }
}

@media (max-width: 480px) {
    .businessesList {
        flex-direction: column;
        align-items: center;
    }

    .businessCard {
        width: 100%;
    }
}