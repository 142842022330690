@import './global-variables.css';

:root {
    /* Existing core colors */
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
    --light-pastel-blue: #C8E6F9;
    --soft-pink: #F4C7E6;
    --pastel-lavender: #D1B2F0;
    --light-gray: #BDC3C7;
    --dark-muted-blue: #2C3E50;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.3s;
    
    /* Map existing colors to new standardized variables */
    --primary-color: var(--soft-blue);
    --primary-dark: #2980b9;
    --primary-light: var(--light-pastel-blue);
    
    --secondary-color: var(--midnight-blue);
    --secondary-dark: #1a252f;
    --secondary-light: #34495e;
    
    /* Business dashboard colors */
    --business-primary: var(--midnight-blue);
    --business-secondary: #34495e;
    --business-accent: var(--soft-blue);
    
    /* Employee dashboard colors */
    --employee-primary: var(--soft-blue);
    --employee-secondary: #2980b9;
    --employee-accent: var(--emerald-green);
    
    /* Common colors */
    --success-color: var(--emerald-green);
    --warning-color: var(--soft-yellow);
    --danger-color: var(--light-coral);
    --info-color: var(--lavender);
    
    /* Background colors */
    --bg-light: var(--off-white);
    --bg-white: #ffffff;
    --bg-dark: var(--midnight-blue);
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto; /* Allow content to determine height */
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto !important; /* Ensure vertical scrolling is enabled */
    font-family: var(--font-family);
    color: var(--midnight-blue);
    background-color: transparent !important;
}