:root {
    --light-pastel-blue: #C8E6F9;
    --soft-pink: #F4C7E6;
    --pastel-lavender: #D1B2F0;
    --light-gray: #BDC3C7;
    --dark-muted-blue: #2C3E50;
    --off-white: #ECF0F1;
}

.header {
    background-color: var(--light-pastel-blue);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
    transition: transform 0.3s, fill 0.3s;
}

.logo:hover {
    transform: scale(1.1);
    fill: var(--pastel-lavender);
}

.navigation-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin-left: -110px;
}

.navigation a {
    color: var(--dark-muted-blue);
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
    transition: color 0.3s;
}

.navigation a:hover {
    color: var(--soft-pink);
}