@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-in {
    animation: fadeIn 0.3s forwards;
}

.fade-out {
    animation: fadeOut 0.3s forwards;
}

.loading-modal-box {
    background-color: rgba(255, 255, 255, 0.2);
}