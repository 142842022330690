:root {
    --midnight-blue: #2c3e50;
    --soft-blue: #3498db;
    --emerald-green: #2ecc71;
    --soft-yellow: #f1c40f;
    --light-coral: #e74c3c;
    --lavender: #9b59b6;
    --off-white: #ecf0f1;
    --dark-gray: #7f8c8d;
}

.mainContent {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.card {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.card h2 {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.card p {
    color: var(--midnight-blue);
    margin-bottom: 20px;
    text-align: center;
}

.card form {
    width: 100%;
    margin-top: 20px;
}

.card form .MuiTextField-root {
    margin-bottom: 20px;
}

.card form .MuiButton-root {
    background: linear-gradient(45deg, #38E7FF, #6B8BFF);
    color: white;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.card form .MuiButton-root:hover {
    background: linear-gradient(45deg, var(--emerald-green), var(--soft-blue));
    transform: translate(-2px, -2px);
    box-shadow: 7px 7px 0 var(--dark-gray);
}

.card form .MuiButton-root:active {
    transform: translate(0, 0);
    box-shadow: 5px 5px 0 var(--dark-gray);
}

.card .MuiButton-outlined {
    color: var(--midnight-blue);
    border-color: var(--midnight-blue);
    margin-bottom: 20px;
}

.card .MuiButton-outlined:hover {
    background-color: var(--off-white);
}

.card .MuiAlert-root {
    margin-top: 20px;
    margin-bottom: 20px;
}

.card .MuiAlert-standardSuccess {
    background-color: rgba(46, 204, 113, 0.1);
    color: var(--emerald-green);
}

.card .MuiAlert-standardError {
    background-color: rgba(231, 76, 60, 0.1);
    color: var(--light-coral);
}