.parallax {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    perspective: 1px;
    z-index: -1;
    pointer-events: none; /* Ensures background doesn't interfere with clicks */
    will-change: transform; /* Optimize for performance */
}

.parallax-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
}

.parallax-layer video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 130%;
    height: 130%;
    /* The static centering transform */
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
}

.parallax-layer img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}